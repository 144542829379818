import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import styles from "./Work.module.scss";
import WorkCards from "../../General/WorkCards";
import Button from "../../General/Button";
import inpactCover from "../../../assets/work/cover/inpact.png";
import hungrymates from "../../../assets/work/cover/hungrymates.png";
const Work = () => {
  const navigate = useNavigate();

  const [yInitial, setYInitial] = useState(50);
  const [yEnd, setYEnd] = useState(-45);

  const handleClick = () => {
    navigate("/work");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const mq1 = window.matchMedia("(max-width: 900px)");
    const mq2 = window.matchMedia("(max-width: 500px)");

    if (mq1.matches) {
      setYInitial(30);
      setYEnd(-30);
    } else if (mq2.matche) {
      setYInitial(20);
      setYEnd(-20);
    }
  }, []);

  return (
    <section className={styles.workSection}>
      <h2>Some of my work</h2>
      <div className={styles.workCardContainer}>
        <ParallaxProvider>
          <Parallax translateY={[yInitial, yEnd]}>
            <div className={styles.parallaxTest}>
              <WorkCards
                id={0}
                imgSrc={inpactCover}
                imgTop={false}
                alt="imagem de um computador a mostrar uma aplicação de telereabilitação chamada INPACT"
                tag3Txt="ux/ui design"
                tag3Id="ux"
                tag2Txt="web/app design"
                tag2Id="web"
                tag1Txt="front-end"
                tag1Id="front"
                home={true}
              />

              <WorkCards
                id={1}
                imgSrc={hungrymates}
                imgTop={true}
                alt="uma página web com uma imagem de um restaurante e um desenho de duas pessoas a brindar"
                tag3Txt="ux/ui design"
                tag3Id="ux"
                tag2Txt="web/app design"
                tag2Id="web"
                tag1Txt="front-end"
                tag1Id="front"
                home={true}
              />

              <WorkCards
                id={2}
                imgSrc="https://drive.google.com/thumbnail?id=1ZVa4MgLVB-mjtLtw9yLKGHHaVplItEeJ&sz=w2000"
                imgTop={false}
                alt="um computador com letras e fundo preto a dizer 'alta e baixa' e uma revista laranja e branca a dizer 'because"
                tag3Txt="web/app design"
                tag3Id="web"
                tag2Txt="front-end"
                tag2Id="front"
                tag1Txt="graphic design"
                tag1Id="graphic"
                home={true}
              />
            </div>
          </Parallax>
        </ParallaxProvider>
        <svg
          className={styles.svgEightStar}
          xmlns="http://www.w3.org/2000/svg"
          width="96"
          height="100"
          viewBox="0 0 96 100"
          fill="none"
        >
          <path
            d="M53.4101 31.9276L53.5779 32.833L54.2459 32.1991L75.7769 11.7684L62.9995 38.5591L62.6032 39.3902L63.5161 39.27L92.9439 35.3968L66.8597 49.5606L66.0505 50L66.8597 50.4394L92.9439 64.6032L63.5161 60.73L62.6032 60.6098L62.9995 61.4409L75.7769 88.2316L54.2459 67.8009L53.5779 67.167L53.4101 68.0724L48 97.2568L42.5899 68.0724L42.4221 67.167L41.7541 67.8009L20.2231 88.2316L33.0005 61.4409L33.3968 60.6098L32.4839 60.73L3.0561 64.6032L29.1403 50.4394L29.9495 50L29.1403 49.5606L3.05609 35.3968L32.4839 39.27L33.3968 39.3902L33.0005 38.5591L20.2231 11.7684L41.7541 32.1991L42.4221 32.833L42.5899 31.9276L48 2.74317L53.4101 31.9276Z"
            stroke="#fa07bc"
          />
        </svg>
        <svg
          className={styles.svgFourStar}
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="70"
          viewBox="0 0 60 70"
          fill="none"
        >
          <path
            d="M30.0133 1.00651C30.0092 19.848 17.0229 35.1193 1.00764 35.1158"
            stroke="#fa07bc"
            strokeLinecap="round"
          />
          <path
            d="M30.0133 1.00651C30.0092 19.848 42.9888 35.1249 59.0041 35.1284"
            stroke="#fa07bc"
            strokeLinecap="round"
          />
          <path
            d="M29.9985 69.2376C30.0025 50.3961 17.0229 35.1193 1.00764 35.1158"
            stroke="#fa07bc"
            strokeLinecap="round"
          />
          <path
            d="M29.9985 69.2376C30.0025 50.3961 42.9888 35.1249 59.0041 35.1284"
            stroke="#fa07bc"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <Button text="See more" onClick={handleClick} />
    </section>
  );
};

export default Work;
