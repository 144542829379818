import React, { useState, useEffect } from "react";
import styles from "./Hero.module.scss";
import arrow from "../../../assets/svg/arrow.svg";
import Globe from "../Globe";

const Hero = ({ href }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const gradientStyle = {
    position: "absolute",
    top: cursorPosition.y - 110,
    left: cursorPosition.x - 25,
    width: "50px",
    height: "50px",
    background: `radial-gradient(circle at 25px 25px, #fa07bc, #100f0f)`,
    borderRadius: "50%",
    filter: "blur(25px)",
  };

  return (
    <div className={styles.bottomHero}>
      <div className={styles.gradient} style={gradientStyle}></div>
      <Globe />

      <h1>
        Hi! I am a creative
        <br />
        <span>*UX/UI Designer*</span>
      </h1>
      <a href={href} className={styles.scrolling}>
        <div
          className={styles.scrollingTxt}
          style={{ width: 118.14, height: 59.97, position: "relative" }}
        >
          <span
            style={{
              left: 0,
              top: 58.56,
              position: "absolute",
              transform: "rotate(-84.94deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            K
          </span>
          <span
            style={{
              left: 2,
              top: 43.95,
              position: "absolute",
              transform: "rotate(-71.10deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            E
          </span>
          <span
            style={{
              left: 6.96,
              top: 31.3,
              position: "absolute",
              transform: "rotate(-57.88deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            E
          </span>
          <span
            style={{
              left: 14.71,
              top: 20.07,
              position: "absolute",
              transform: "rotate(-44.36deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            P
          </span>
          <span
            style={{
              left: 24.86,
              top: 11.09,
              position: "absolute",
              transform: "rotate(-33.98deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            {" "}
          </span>
          <span
            style={{
              left: 31.41,
              top: 6.87,
              position: "absolute",
              transform: "rotate(-23.59deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            S
          </span>
          <span
            style={{
              left: 44.81,
              top: 1.7,
              position: "absolute",
              transform: "rotate(-9.12deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            C
          </span>
          <span
            style={{
              left: 60.12,
              top: 0,
              position: "absolute",
              transform: "rotate(5.35deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            R
          </span>
          <span
            style={{
              left: 74.41,
              top: 1.95,
              position: "absolute",
              transform: "rotate(20.13deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            O
          </span>
          <span
            style={{
              left: 88.99,
              top: 8.17,
              position: "absolute",
              transform: "rotate(34.29deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            L
          </span>
          <span
            style={{
              left: 99.36,
              top: 15.92,
              position: "absolute",
              transform: "rotate(46.87deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            L
          </span>
          <span
            style={{
              left: 107.47,
              top: 25.43,
              position: "absolute",
              transform: "rotate(57.26deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            I
          </span>
          <span
            style={{
              left: 112.21,
              top: 33.19,
              position: "absolute",
              transform: "rotate(68.90deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            N
          </span>
          <span
            style={{
              left: 117.15,
              top: 47.98,
              position: "absolute",
              transform: "rotate(84.31deg)",
              transformOrigin: "0 0",
              color: "#FFF7E1",
              fontSize: 11,
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            G
          </span>
        </div>
        <img src={arrow} alt="" />
      </a>
    </div>
  );
};

export default Hero;
