import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import styles from "./About.module.scss";
import Button from "../../General/Button";

import meImg from "../../../assets/me/ice.png";

const About = ({ idAbout }) => {
  const navigate = useNavigate();

  const handleCVClick = () => {
    window.open(
      "https://drive.google.com/file/d/1x4nysItjGDJ_XjFOOfvskglHOTaB_aVm/view?usp=sharing",
      "_blank"
    );
  };

  const handleMoreAboutMeClick = () => {
    navigate("/about");
    window.scrollTo(0, 0);
  };

  const [showMeImg, setShowMeImg] = useState(false);

  const onHover = () => {
    setShowMeImg(!showMeImg);
  };

  return (
    <section className={styles.aboutSection} id={idAbout}>
      <div className={styles.flowerDiv}>
        <svg
          className={styles.flower}
          xmlns="http://www.w3.org/2000/svg"
          width="285"
          height="285"
          viewBox="0 0 285 285"
          fill="none"
        >
          <g opacity="0.9" filter="url(#filter0_f_1442_121)">
            <circle cx="142.5" cy="142.5" r="42.5" fill="#FF20C7" />
          </g>
          <path
            d="M166.616 199.992C160.679 220.835 151.791 234.112 141.994 234.004C132.197 233.896 123.604 220.426 118.128 199.457M167.889 84.5615C162.413 63.5926 153.821 50.1229 144.024 50.0148C134.227 49.9067 125.339 63.1836 119.402 84.0267M200.852 166.786C221.595 161.305 234.896 152.759 235.003 143.024C235.111 133.289 222.001 124.452 201.385 118.515M85.1653 117.233C64.4227 122.713 51.1217 131.259 51.0143 140.994C50.9062 150.797 64.1976 159.689 85.0603 165.626M166.502 200.495C184.995 211.235 200.395 214.571 207.341 207.777C214.331 200.94 211.254 185.318 200.767 166.435M85.2072 117.506C74.755 98.657 71.6969 83.069 78.6766 76.2416C85.6225 69.4472 101.023 72.7826 119.516 83.5233M201.494 118.516C212.235 100.023 215.57 84.6226 208.776 77.6767C201.982 70.7308 186.512 73.7256 167.786 84.0557M118.231 199.962C99.5055 210.292 84.0354 213.287 77.2411 206.341C70.4136 199.361 73.8149 183.845 84.6804 165.231"
            stroke="#FFBBEE"
          />
          <path
            d="M154.853 171.496C151.806 182.194 147.244 189.008 142.216 188.953C137.188 188.897 132.778 181.984 129.967 171.222M155.507 112.252C152.696 101.49 148.286 94.5763 143.258 94.5208C138.229 94.4654 133.668 101.28 130.621 111.977M172.425 154.453C183.071 151.641 189.898 147.254 189.953 142.258C190.008 137.261 183.28 132.725 172.698 129.678M113.049 129.02C102.403 131.833 95.576 136.219 95.5208 141.216C95.4653 146.247 102.287 150.811 112.995 153.858M154.795 171.755C164.286 177.267 172.191 178.979 175.756 175.492C179.343 171.983 177.764 163.965 172.382 154.273M113.07 129.16C107.706 119.486 106.136 111.486 109.719 107.982C113.284 104.495 121.188 106.206 130.679 111.719M172.755 129.679C178.267 120.187 179.979 112.283 176.492 108.718C173.005 105.153 165.065 106.69 155.454 111.992M130.02 171.481C120.409 176.783 112.469 178.32 108.982 174.755C105.478 171.173 107.223 163.209 112.8 153.656"
            stroke="#FFBBEE"
          />
          <defs>
            <filter
              id="filter0_f_1442_121"
              x="0"
              y="0"
              width="285"
              height="285"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="50"
                result="effect1_foregroundBlur_1442_121"
              />
            </filter>
          </defs>
        </svg>
      </div>

      <div className={styles.aboutMeText}>
        <Fade duration={2500}>
          <p>
            Hey there 👋🏻!{" "}
            <span
              className={styles.underlineHover}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {" "}
              I'm Francisca
            </span>
            , and it's a pleasure to meet you!
            <br />
            <br />I am a designer who thrives on working on{" "}
            <mark>bold and creative projects 🎨</mark>
            .
            <br />
            <br />
            I am a proud cat mom 💛, and when it comes to my work, I put in the
            same level of passion and care that I give to my beloved feline
            companions 🐱.
            <br />
            <br />
            Right now, I’m working at Bosch Portugal and always on the{" "}
            <mark>lookout for exciting new opportunities</mark> to dive into.
            Let’s create something amazing together! 👩🏻‍💻.
          </p>
        </Fade>
        <Fade duration={3500}>
          <div className={styles.aboutButtons}>
            <Button text="My CV" anotherButton={true} onClick={handleCVClick} />
            <Button text="More about me" onClick={handleMoreAboutMeClick} />
          </div>
        </Fade>
      </div>

      <div className={styles.imgContainer}>
        {showMeImg && (
          <Fade duration={1000}>
            <img className={styles.meImg} src={meImg} alt="Imagem 1" />
          </Fade>
        )}
      </div>
    </section>
  );
};

export default About;
