//import images from "../Data/workBodyImg";

// id:0
import inpactGam1 from "../assets/work/inpact/gam1.png";
import inpactGam2 from "../assets/work/inpact/gam2.png";
import inpactVisual from "../assets/work/inpact/visual.png";
import inpactTypo from "../assets/work/inpact/typo.png";
import inpactColor from "../assets/work/inpact/color.png";
import inpactComp from "../assets/work/inpact/comp.png";
import inpactHigh1 from "../assets/work/inpact/high1.png";
import inpactHigh2 from "../assets/work/inpact/high2.png";
import inpactError from "../assets/work/inpact/evalerror.png";
import inpactConc from "../assets/work/inpact/evalconc.png";
import inpactSusNet from "../assets/work/inpact/evalsusnet.png";
//id:1
import hungry31 from "../assets/work/hungrymates/31.png";
import hungry32 from "../assets/work/hungrymates/32.png";
import hungry33 from "../assets/work/hungrymates/33.png";
import hungry42 from "../assets/work/hungrymates/42.png";
import hungry44 from "../assets/work/hungrymates/44.png";
import hungry451 from "../assets/work/hungrymates/452.png";
import hungry452 from "../assets/work/hungrymates/451.png";

//id:3 - together apart
import together311 from "../assets/work/together/31.png";
import together41 from "../assets/work/together/41.png";
import together421 from "../assets/work/together/421.png";
import together422 from "../assets/work/together/422.png";
import together431 from "../assets/work/together/431.png";
import together432 from "../assets/work/together/432.png";
import together51 from "../assets/work/together/51.png";
import together52 from "../assets/work/together/52.png";
import together611 from "../assets/work/together/611.png";
import together612 from "../assets/work/together/612.png";
import together621 from "../assets/work/together/621.png";
import together622 from "../assets/work/together/622.png";

//id:4 - weather app
import weather411 from "../assets/work/weather/411.png";
import weather421 from "../assets/work/weather/421.png";
import weather431 from "../assets/work/weather/431.png";
import weather432 from "../assets/work/weather/432.png";

//id:5 - Alta & Baixa
import altaBrainstorm from "../assets/work/altabaixa/brainstorm.png";
import altaTypoPhoto from "../assets/work/altabaixa/typophoto.png";
import altaInspo from "../assets/work/altabaixa/inpomagazine.png";
import altaSketch from "../assets/work/altabaixa/sketches.png";
import altaPag1 from "../assets/work/altabaixa/revista1.jpg";
import altaPag2 from "../assets/work/altabaixa/revista2.jpg";
import alta31 from "../assets/work/altabaixa/31.png";
import alta321 from "../assets/work/altabaixa/321.png";

//id: - feels good

//id: - tasco galo

export const workBodyData = [
  {
    id: 0,
    topic1: "🤸🏻‍♀️ Rehabilitation and Telerehabilitation",
    topic2: "⚠️ The Problem",
    topic3: "🔎 The Solution",
    topic4: "📝 Heuristic Evaluation",
    topic5: "🎯 Gamification Strategy",
    subTopic51: "* Delineate",
    subTopic52: "* Devise",
    img521: inpactGam1,
    img522: inpactGam2,
    topic6: "💻 Prototype",
    subTopic61: "* Visual Research",
    subTopic62: "* Graphic Identity",
    subTopic63: "* Components",
    subTopic64: "* High-Fidelity",
    subTopic65: "* Implementation",
    img611: inpactVisual,
    img621: inpactTypo,
    img622: inpactColor,
    img631: inpactComp,
    img641: inpactHigh1,
    img642: inpactHigh2,
    topic7: "📊 User Interface Evaluation",
    subTopic71: "* Pre-session Questionnaire",
    subTopic72: "* Tasks",
    subTopic73: "* Post-session Questionnaire",
    img721: inpactError,
    img722: inpactConc,
    img731: inpactSusNet,
    topic8: "🏆 Conclusions",

    txt1: "<b>Rehabilitation</b> is essential for individuals to remain independent.<br/> <br/> <b>Telerehabilitation</b> is the provision of rehabilitation services through the internet and communication technologies. It enables rehabilitation services to be brought to everyone, everywhere.",

    txt2: "The repetitive nature of rehabilitation exercises causes patients to lose interest and focus, which makes <b>long-term motivation and adherence unattainable</b>, posing a problem for both rehabilitation and telerehabilitation.",

    txt3: "This project had has is main objective the <b>redesign</b> of an existing telerehabilitation application (INPACT). In the redesign was included the creation and implementation of a motivational strategy to promote continued use and encourage people undergoing rehabilitation to follow their plans. The chosen approach was <b>gamification</b>.",

    txt4: "We started by doing an heuristic evaluation to identify all problems with the previous user interface. Has there were no heuristic list capable of assessing the application necessities we have made a compilation of 33 heuristics (Nielsen, Persuasive System Design and Game Design Principles). The heuristic compilation list can be found in the following link: <a href='https://drive.google.com/file/d/1AmHWr-BhN-nkYdlzksmJ2U4g5vTSEpqy/view' target='_blank'>heuristic principles</a>. <br/> <br/> The heuristic evaluation was conducted with four experts. 52 problems were identified and the most violated heuristic was H8 - Aesthetic and minimalist design. <br/> <br/>Following the evaluation the requirements for the application were defined and they were divided in eight categories.",

    txt5: "For the gamification strategy,  decided to adopt the six steps to gamification by Werback and Hunter. The six steps of the framework are: Define; Delineate; Describe; Devise; Don’t forget the fun; Deployment of the appropriate tools.<br/> <br/>We will only concentrate on the delineate and devise phases of the strategy as we can explain the strategy in its entirety with just these two steps.",

    txt51:
      "In this phase we defined the target behaviours we wanted our users to do:<ul><li>Visit the application according to the periodicity of the prescribed plan;</li><li>Complete the session exercises;</li><li>Complete the sessions itself;</li><li>Evaluate all sessions performed;</li><li>Follow and complete the prescribed plan.</li></ul>",

    txt52:
      "In this phase we defined the goals and levels. To do this we followed the following steps:<ul><li><b>Theme</b>: Garden;</li> <li><b>Narrative</b>: Complete the garden and keep it alive -> How? Delivering the desired behaviours;</li> <li><b>Long-term goal</b>: Complete the garden;</li> <li><b>Short-term goals</b>: Unlock new elements and keeping the garden alive (green).</li></ul>",

    txt61:
      "We started by searching for similar products and UI’s of the functionalities the applications was going to have.",

    txt64:
      "According to the problems identified in the heuristic evaluation, the gamification strategy, the new graphic identity and components we designed a medium and high fidelity prototype, in Figma. In the images below we will see two of the new screens and the correspondent old version of the user interface (to see all the high-fidelity screens please refer to the following <a href='https://www.figma.com/file/1B7iuTXbPg2vH1kk1w1MyO/INPACT?type=design&node-id=0%3A1&mode=design&t=98wjyby7umFch9UH-1' target='_blank'>Figma file</a>).",

    txt65:
      "The implementation of the prototype was done in React.js. The implemented version is only a proof of concept and the information present is dummy. To see the website you can access <a href='https://inpact-utente.vercel.app/' target='_blank'>here</a>. To better understand all the functionalities a video (audio in portuguese) was created: <a href='https://youtu.be/nUjzUy-GyME' target='_blank'>video link</a>. The application was developed for computer and tablet.",

    txt7: "For the user interface evaluation a usability and user experience evaluation with 47 participants was conducted. 24 people who volunteer to test the application (group A) and 23 participants were from physiotherapy clinics (group B). The purpose of doing with two different groups of participants was to test if the application could be easily used by both. <br/> <br/> Regarding the methods and procedures the test was divided in three major parts: <ul> <li><b>Pre-session questionnaire</b> (consent and personal questions)</li> <li><b>Tasks</b> (13 tasks)</li> <li><b>Post-session questionnaire</b> (seven open-ended questions, system usability scale and net promoter score)</li> </ul>",

    txt71:
      "<b>Group A</b>: Younger public with more experience with technology. <br/> <br/> <b>Group B</b>: Older public with less experience with technology.",

    txt72:
      "For the usability evaluation the taps on screen, time on task, error and task completion metrics were collected. <br/> <br/>The taps on screen and time on task were similar between groups and the average was within the expected value. <br/> <br/> Errors were recorded and were analysed to be redesign in the next iterations. The same happened with task completion.",

    txt73:
      "The users stated that they did not had any significant difficulties and  felt motivated to use the application regularly and complete the garden. <br/> <br/>Their favourite screens were the perform exercise, the garden and the session information page.",

    txt8: "Overall the results were positive. Some errors were recorded that need to be redesign in the following iteration. It is expected that the application will be fully integrated with a data base and machine learning, that is being developed by other members of the project. <br/> <br/>When we have everything integrated a pilot test should be conducted to test the effectiveness of the motivational strategy.",
  },
  //---------------------------------------------------------------------------------
  //------------------------------------hungry mates---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 1,
    topic1: "⚠️ The Problem",
    txt1: "It's never easy to decide where to go, either to lunch, to go out, or anything else within a group. When people have varying viewpoints and preferences, things get even more complicated. Therefore, we were given the task of developing a technology-based project that would simplify the process of scheduling an event.",
    //
    topic2: "🔎 The Solution",
    txt2: "Our project aims to address this issue by improving the democratic and effective nature of the event scheduling process for friends, family, and co-workers. As a result, we have develop a website where individuals can come together in groups and vote, through polls, on locations and dates in a more relaxed and focused place.",
    //-----------------
    topic3: "🏃🏻‍♀️ Design Sprint",
    txt3: "The design sprint phase allowed us to understand what already existed, our stakeholder needs and getting an overall preliminary sense of the final product. This phase contemplated the following steps: <ul><li>User interviews</li><li>How Might We's</li> <li>User stories</li> <li>Defining the product goals</li> <li>Golden path</li> <li>Main Flow</li></ul>",
    subTopic31: "User interviews and How Might We's",
    img311: hungry31,
    txt31:
      "The user interviews were conducted to understand who our target audience is, their goals, problems and needs. To conduct the interviews first a script was drawn up in team and then we split to conduct the interviews. The interviews were conducted with 17 people that worked at the building were RedLight Software is nested. <br/> <br/> The results from the interviews were analysed so we could define our How Might We's (HMW). The findings were divided in nine main groups of HMW: Reviews; Split Bill; Price; Number of People; Time; Location; Notify; Menu; and Privacy.",
    subTopic32: "User stories and Product Goals",
    img321: hungry32,
    txt32:
      "Following we wrote the user stories. These helped us to uncover and establish the software features for the product, based in our end users perspective. The user stories were divided in three main groups: Users; Restaurants; and Group Admin. <br/> <br/> Based on the user stories we defined our product goals to the following: <ul> <li>Voting in locations/dates;</li> <li>Suggestion of new locations/dates;</li> <li>Bill management.</li></ul>",
    subTopic33: "Golden Path and Main Flow",
    img331: hungry33,
    txt33:
      "On the image below we can see the main flow and golden path represented in color.",
    //-----------------
    topic4: "💻 Prototype and Implementation",
    txt4: "At this point we were ready to start the prototyping phase. We have decided as a team the product was going to be a website. The prototype was developed in Figma and the final website was implemented in React.js, using Typescript and Sass.",
    subTopic41: "* Visual Research",
    txt41:
      "We started by looking at similar products already in the market and user interfaces with the functionalities we wanted to implement.",
    subTopic42: "* Branding",
    img421: hungry42,
    txt42:
      "Searching for inspiration for the application, as originally it was going to be a website to plan meals, we started by searching for food related images. The color palette was inspired by the yellows, oranges and reds from the images we found related to food.",
    subTopic43: "* Components",
    img431: hungry44,
    txt43:
      "We had the idea of creating a modular website, so the design of the components was the key to create the prototypes. We started by creating the components in Figma and then we used them to design the high-fidelity prototype. The image below shows some of the components created, all the components can be found in the following <a href='https://www.figma.com/file/apJuGnvEiStfxVq9j1QUwK/Hungry-Mates?type=design&node-id=6%3A8534&mode=design&t=ElJyL7KaMaGi8DYJ-1' target='_blank'>link</a>.",
    subTopic44: "* Medium and High-Fidelity",
    img441: hungry451,
    img442: hungry452,
    txt44:
      "The medium and high-fidelity versions of the home screen are displayed below on the image. The medium-fidelity prototype was designed before the creation of the components. This prototype was tested with possible end-users that work at RedLight Software and other companies nested at the same building. Through the analysis of the evaluation results some changes were made that were then incorporated in the high-fidelity prototype. <br/> <br/>To see all the medium and high-fidelity screens please refer to the following <a href='https://www.figma.com/file/apJuGnvEiStfxVq9j1QUwK/Hungry-Mates?type=design&node-id=6%3A17844&mode=design&t=ElJyL7KaMaGi8DYJ-1' target='_blank'>link</a>",
    // topic5: "🏆 Conclusions",
    // txt5: "",
  },
  //---------------------------------------------------------------------------------
  //------------------------------------Sapient---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 2,
    topic1: "⚠️ The Problem",
    txt1: "As a result of the expanding AI industry, more and more websites and companies are venturing into the field. My objective was to come up with an identity that would differentiate Sapient for the remaining well-established companies, while preserving Sapient's core values.",
    //-----------------------
    topic2: "🎨 Brand Identity",
    subTopic21: "* Visual Research",
    txt21:
      "To begin the work, I started by creating a mood board to convey what I envision for the brand identity. Having in mind the keywords: tech, modern, AI, tech consulter and trustworthy.<br/> <br/>Additionally I searched for company website inside the same niche that were already working in the business.",
    img211:
      "https://drive.google.com/thumbnail?id=1KggFyU540M2S9FjNO0LfVxAyB8pTag2L&sz=w2000",
    img212:
      "https://drive.google.com/thumbnail?id=1YzztZWFFKfAzIWY93MuJWkoEhkuOM1U3&sz=w2000",
    subTopic22: "* Logo",
    txt22:
      "I began by drawing some potential logos. Since the name of the brand is Sapient Studios, I decided to try and play around with the letter “S”. After crafting some ideas, and iteratively talk with the Sapient team, I designed a version that everyone preferred, which you can see in the images below. The logo shaped the way for the rest of the identity.",
    img221:
      "https://lh3.googleusercontent.com/d/1GymBAJlTvFZGBtHsMxH-1SZ7oFSxhBwv=w2000",
    img222:
      "https://lh3.googleusercontent.com/d/1QHwCJ0V5AfW1nBpQ1j4uS1P9HR6K2lVV=w2000",
    subTopic23: "* Colours",
    txt23:
      "The dark, kinda mysterious, colours were predominant in my initial research for companies int the field. As a result, the main color palette was created having in mind a darker background. This palette is the main one and is in all the Sapient Studio branding. Nevertheless, I also created a color palette for lighter backgrounds. The light version is only used if they need to use the logo in a lighter background.<br/> <br/> Having in mind color accessibility, all colours pass in the contrast check for graphics and the main color of the dark palette passes in the contrast check for text. Since the secondary and tertiary colour are never used in text, they do not need to pass the contrast check for text. The same goes for the light palette. You can check these colour palettes in the images below.",
    img231: "https://i.imgur.com/FQ2tSyI.png",
    img232: "https://i.imgur.com/Ejn89rr.png",
    //-----------------------
    topic3: "💻 Website Design",
    txt3: "After creating the brand identity it was time to apply what we had designed on the website. The website was designed in Figma and is a single responsive website page. <br/> <br/> One predominant element in the branding are gradients, so that was something I needed to bring for the website. You will see gradients mostly in decorative elements, that fill the empty spaces of the website without overloading each individual frame. To see the full prototype please refer to the following <a href='https://www.figma.com/design/2JnqkpfSG2981EDwyAwkw1/Sapient-Studios?node-id=8-2888&t=xGcXyaxCjy6Erj5D-1' target='_blank'>Figma link</a>",
    img3: "https://i.imgur.com/t2ZLvto.png",
    img31: "https://i.imgur.com/E52ppmZ.png",
    topic4: "💭 Conclusion",
    txt4: "This was my first freelance project and I believe it was a success. Not just because of the final design, but because of the seamless collaboration with the Sapient Studios team. The brand identity was well received by the Spaient team, and their active involvement in the ideation process was valuable for the final proposal. The website was successfully implemented by their developers and can be accessed <a href='https://sapientstudios.vercel.app//' target='_blank'>here</a>. Seeing it live is the best validation of our work.",
    //-----------------------
  },
  //---------------------------------------------------------------------------------
  //------------------------------------together apart---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 3,
    topic1: "⚠️ The Problem",
    txt1: "We chose the issue relating to the current procedure for family members/nursing home assistant to know the status/location of a patient in the emergency room. <br/> <br/>As we conducted the research, we came to the conclusion that many people had already encountered this issue and that it needed to be resolved as the current method is time-consuming, inaccurate, and ultimately frustrating and stressful.",
    //-----------------------
    topic2: "🔎 The Solution",
    txt2: "Numerous methods from the field of service design were taken into consideration in order to arrive at the most effective solution to solve the issue. <br/> <br/>We used the double diamond approach for this project, but we did not reached the delivery phase because it was mandated by the course unit. Following, we'll look into this concept and the tools that were used to create the final product.",
    //-----------------------
    topic3: "🗺️ Discover",
    subTopic31: "* Interviews",
    img311: together311,
    txt31:
      "To better understand the system for receiving updates on a patient's condition in the emergency room, we interviewed a <mark style='background-color:#FFF3B0;'>doctor (Doc_01)</mark>, a <mark style='background-color:#CFFECB;'>nursing home assistant (nu_ass_01)</mark>, <mark style='background-color:#C2DBFF;'>two nurses (nur_01,nur_02)</mark> and <mark style='background-color:#FFB7DE;'>two individuals (fam_mem_01, fam_mem_02)</mark> who had experienced waiting for updates on their family members. A standardized script was used with tailored questions based on the interviewee's profile. Key insights from the interviews can be seen in the image below.",
    subTopic32: "* Gaps Encountered",
    txt32:
      "After analysing the interviews some questions were brought up: <ul> <li>'Social workers only work until 5pm, who contact the families then?'</li> <li>'Only one social worker working'</li> <li>'Which nursing home professional calls the hospital for information? How do they know that the nursing home assistant is authorised to know the patient's condition?'</li> <li>'Ticket system for information on users with restricted opening hours, how do you get information outside these hours?'</li></ul>",
    //-----------------------
    topic4: "📌 Define",
    subTopic41: "* Stakeholders",
    img411: together41,
    txt41:
      "The analysis helped us identify stakeholders and create a stakeholder map. <br/><br/> Our primary users are companions (family or friends) and nursing home assistants. Our objective is to improve how carers learn about patients in the emergency room. Although they share the same goal, their underlying causes are distinct.",
    subTopic42: "* Jobs to be Done",
    img421: together421,
    img422: together422,
    txt42:
      "After defining the stakeholder, we look for the underlying reasons and needs to why they would use the product we were going to develop. For this, we specified the jobs to be done for each client (companion and nursing home assistant). This process also helped us realise what should be included in the final product.",
    subTopic43: "* Value Proposition Canvas",
    img431: together431,
    img432: together432,
    txt43:
      "To continue the process of understanding the customer values and needs a value proposition canvas was made for each client. This allowed us to define the requirements for our product and the product itself. To see each value proposition canvas in greater detail please refer to the following <a href='https://miro.com/app/board/uXjVMqoxzSA=/?share_link_id=130683691553' target='_blank' target='_blank'>miro file</a>.",
    //-----------------------
    topic5: "♟️ The Final Solution",
    img5: together51,
    img51: together52,
    txt5: "As a result of the iterative process that occurred thus far we came to a possible solution for the problem. Creation of a platform where the user could virtually accompany the patient. The companion will be able to know where the patient is, what medical exams he has already taken and if there is a time prediction to be discharged.<br/> <br/> Regarding security, to see a patients information both the user and the patient must have an account in the application and the patient must generate a pass code for the user to see the information. <br/> <br/> The platform is an application that can be access through a phone or physic electronic devices in the hospital. Ideally, the application would be linked to the hospital system and the data would be synchronised using the patient's health number. This solution is just a proof of concept. (Poster designed by Teresa Martins and 3D model by Rafaela Costa)",
    //-----------------------
    topic6: "👷🏻‍♀️ Develop",
    subTopic61: "* Graphic Identity",
    img611: together611,
    img612: together612,
    subTopic62: "* High-Fidelity Prototype",
    img621: together621,
    img622: together622,
    txt62:
      "To see the entire prototype, graphic identity, design system and 3-D model please redirect to the following <a href='https://www.figma.com/file/ltEzwWWRzCsY8gkk2yL3fC/Design-de-Servi%C3%A7os-Final?type=design&node-id=0%3A1&mode=design&t=UC0cK4XHTRNnBPEr-1' target='_blank'>figma link</a>. <br/> <br/>I was in charge of developing the graphic identity, mobile mockups and design system of the same. The remaining tasks were divided between my three colleagues.",

    //-----------------------
    topic7: "📽️ The Process",
    txt7: "To better understand the project a video of a possible interaction was made (video with audio in portuguese) that can be seen at the following <a href='https://youtu.be/leEWmOqrbNA' target='_blank'>link</a>.",
  },
  //---------------------------------------------------------------------------------
  //------------------------------------weather app---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 4,
    topic1: "🏃🏻 45 Min UI Challenge",
    txt1: "What is this challenge? In short, I challenge myself to create a user interface in 45 minutes. The 45 minutes are divided in: review current work (5 minutes); defining requirements (5 minutes); searching for visual components (5 minutes); low-fidelity prototype (7 minutes); graphic identity (8 minutes); and high-fidelity prototype (15 minutes). <br/> <br/> The last two phases, graphic identity and high-fidelity prototype, after the time limit were then improved to be able to be presented. However, what was done within the expected time will be also shown. For this challenge, as you may have noticed, I chose to design a weather application.",
    //-----------------------
    topic2: "📑 Current Work",
    txt2: "The first step was to analyse already in-market weather applications. This allowed me to see features that are essential and trivial throughout all applications. The applications I found in under 5 minutes were from Apple, Google, IPMA, AccuWeather, CARROT and MyRadar. <br/> <br/> Some characteristics that stood out were: the current temperature, which is the biggest element in all, the maximum and minimum temperatures of the day, the term sensation, how the sky is (e.g., partly cloudy, raining), the wind, precipitation, and humidity levels, how the weather will evolve throughout the day, and some show the weather for the entire week.",
    //-----------------------
    topic3: "📝 The Requirements",
    txt3: "Having analysed the current work, and based on the characteristics found I defined the requirements for the application: <ul> <li>See weather for all week</li> <li>See weather for the next 24 hours</li> <li>See weather at different cities</li> <li>Switch from cities</li> <li>See wind, rain and humidity value</li> <li>Weather degree at the moment, as well as max and minimum</li> <li>Time of sunrise and sunset</li> <li>Weather warnings</li> </ul>",
    //-----------------------
    topic4: "👩🏻‍💻 Prototype",
    subTopic41: "* Visual Reasearch",
    img411: weather411,
    txt41:
      "I started by conducting visual research to find components and other weather app prototypes. I focus on finding weather apps with a distinguished design compared to the commercialized ones. One that stood out used colors to represent how the weather was, instead of showing an illustration, or image, of the sun, clouds, rain, etc.",

    subTopic42: "* Low-fidelity Prototype",
    img421: weather421,
    txt42:
      "After the search for visual inspiration the paper wireframes followed. I had 7 minutes to draw them so I decided to focus on the ‘main page’ which is the one that shows the weather for a specific place.",

    subTopic43: "* High-Fidelity Prototype",
    img431: weather431,
    img432: weather432,
    txt43:
      "To end, I designed the high-fidelity prototype in Figma. I decided to do 2 screens, a loading first screen and the ‘main screen’, drawn during the low-fidelity prototype. <br/> <br/> Has stated in the beginning, there are many weather apps in the market, so I wanted to design something different. Based on the ‘CARROT’ app, mentioned in the current work section, I decided that my app would give unwanted advice and activities to do while saying what is the weather for the day (e.g. ‘Today is going to be a sunny day! Perfect to invite that one person (you know who) and ask to go have lunch 😉’; ‘Today is going to be a cloudy day, but no rain! You should move that lazy ass out of the couch and go for a run 🏃🏻.’).<br/> <br/> Why this? I just wanted to do something funny, and unusual, with my weather prototype,  and still make it visually appealing and accurate. Additionally, I liked the idea of using colours to represent the weather for the day, as I mentioned in the visual research phase. So, each type of weather has its gradient associated. <br/> <br/> In the images, you can see the main page version I did in 15 minutes and the final result after having more time to complete it.",
  },
  //---------------------------------------------------------------------------------
  //------------------------------------alta & baixa---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 5,

    topic1: "💭 The Concept",
    txt1: "The first step was to come up with a concept and ideas for the magazine. Because this is a Coimbra magazine, we wanted to incorporate that sense into the publication.<br/><br/> As the magazine is called 'Alta & Baixa' we decided to explore the up and down town streets of Coimbra. The orange colour of the roofs of the houses that stood out against the landscape was the first thing that caught our attention. Asides, we noticed the unique typography on every door, shop, wall, and so on as we walked through the streets of Coimbra. <br/><br/> And so this was the beginning. We decided to use these two elements as the main concept of the magazine. The orange colour of the roofs and the typography of the city.",
    img1: altaBrainstorm,
    img11: altaTypoPhoto,
    //
    topic2: "📕 The Magazine",
    txt2: "We began to investigate magazine organisation and material after defining the concept.",
    subTopic21: "* Visual Research",
    txt21:
      "We started by looking at the different types of magazines that exist and how they are organised. Simultaneously we looked at the different types of paper and binding that exist as we wanted to have pages in full orange.",
    img211: altaInspo,
    subTopic22: "* First Sketches",
    img221: altaSketch,
    subTopic23: "* Final Design",
    txt23:
      "The orange colour and idiomatic typography on the images, as you can see, marks its presence on the magazine. To see the entire magazine please refer to the following <a href='https://drive.google.com/file/d/1izd9YdDe64k3bqHI3JJgtKmxFOqSyQUl/view?usp=drive_link' target='_blank'>link</a>.",
    img231: altaPag1,
    img232: altaPag2,
    //
    topic3: "💻 The Website",
    subTopic31: "* Visual Research",
    img311: alta31,
    txt31:
      "The same rules for the magazine applied for the website. As a result, most of the design decisions were already defined. As the vernacular typography has imperfections and a more distress look we decided we wanted to transmit the same in the website, and so we search for some visual inspiration.",
    subTopic32: "* High-Fidelity Prototype",
    img321: alta321,
    txt32:
      "The website ended up being a single page website, with the same structure as the magazine. Currently I am having problems retrieving the implemented website, but it was implemented in React JS. In the implemented version the letters of the hero are not static, as in the prototype, they change to a different vernacular typography. To see the entire website please refer to the following <a href='https://www.figma.com/file/BazrFuVNHJ912bWzRsMy44/Alta-Baixa?type=design&node-id=0%3A1&mode=design&t=fCP0P1sHFYwIJ28W-1' target='_blank'>link</a>.",
  },

  // //---------------------------------------------------------------------------------
  // //------------------------------------tasco do galo---------------------------------
  // //---------------------------------------------------------------------------------
  // {
  //   id: 5,
  //   topic1: "xxx",
  // },
];
